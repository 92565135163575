// Sidebar
$sidebarWidth: 280px;

// Body
$body-bg: #fff;

// Colors
$primary: #b08106;

// Typography
$font-family-sans-serif: 'Poppins', sans-serif;
$font-size-base: 0.82rem;
$line-height-base: 1.75;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;
$font-weight-bolder: bolder !default;

// Transitions
$transition-duration: .2s;
$transition-easing: ease-in-out;

// Border radius
$border-radius: 6px;

// Sidebar
$sidebar-title-color: #000;
$sidebar-link-color: #333