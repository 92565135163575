// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Animate
@import '~animate.css/animate';

body {
    background: #eee;

    &::-webkit-scrollbar { width: 12px; height: 12px; }
	&::-webkit-scrollbar-track { background: none; }
	&::-webkit-scrollbar-thumb { background: darken($secondary, 10); }
	&::-webkit-scrollbar-thumb:hover { background: darken($secondary, 30); }
}

.sidebar {

    position: relative;
    width: 100%;

    @media only screen and (min-width : 992px) {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: $sidebarWidth;
        height: 100vh;
    }

    overflow-y: scroll;

    &::-webkit-scrollbar { width: 5px; height: 5px; }
	&::-webkit-scrollbar-track { background: none; }
	&::-webkit-scrollbar-thumb { background: darken($warning, 10); }
	&::-webkit-scrollbar-thumb:hover { background: darken($warning, 30); }

    a { color: $sidebar-link-color; }

    .navbar-nav {

        .nav-link {
            font-size: 100%;
            opacity: .6;
            border-bottom: transparent 1px solid;
            display: inline-block;
            padding: .1rem 0;

            &:hover, &:active {
                opacity: 1;
                border-bottom: $sidebar-link-color 1px solid;
            }
        }

        .divider {
            display: block;
            margin: 2rem 0 .75rem 0;
            font-weight: 500;
            letter-spacing: -0.015rem;
            font-size: 95%;
            color: $sidebar-title-color;
            text-transform: uppercase;
        }
    }
}

main {

    @media (min-width: 992px) {
        padding-left: $sidebarWidth;
    }
}

.container-fluid {

    @include media-breakpoint-up(md) {
        padding: 0 40px;
    }
}

.title {
    // color: #fff;
    background: #f8f9fa;
    padding-bottom: .5rem;

    // h1, h2, h3, h4 { color: #fff; }
}

.breadcrumbs {
    border-top: #dee2e6 1px solid;
    padding: .75rem 0;
    background: #f8f9fa;
    margin-bottom: 30px;
}
.breadcrumb {
    background: none;
    padding: 0;
    margin: 0;
    font-size: 90%;

    .breadcrumb-item {
        color: #999;
    }
    .breadcrumb-item a {
        color: #555;
        text-decoration: underline;

        &:hover { text-decoration: none; }
    }
}

.table-responsive {
    &::-webkit-scrollbar { width: 5px; height: 5px; }
	&::-webkit-scrollbar-track { background: none; }
	&::-webkit-scrollbar-thumb { background: darken($warning, 10); }
	&::-webkit-scrollbar-thumb:hover { background: darken($warning, 30); }
}

.table th {
    border-top: none;
    font-size: 80%;
    text-transform: uppercase;
    color: #777;
}
.table td, .table th {
    vertical-align: middle;
}
.table td {
    padding: .35rem .75rem;
}
.table-actions {
    border-left: #ddd 1px solid;
    text-align: center;
}

.table-nowrap {
    white-space: nowrap;
}

.btn-table-actions {
    padding: .1rem .5rem;
}

label {
    margin-bottom: 3px;
    font-weight: 500;
}

.form-text {
    color: #aaa;
    font-size: 88%;
}
.form-footer {
    position: sticky;
    bottom: 0;
    z-index: 250;

    .card-body {
        background-color: lighten($success, 50);
    }
}

.attribute {

    strong {
        font-size: 75%;
        color: #555;
        text-transform: uppercase;
        font-weight: 700 !important;
    }

    padding: 7px 15px;
    margin: 10px 0;
    border-radius: $border-radius;
    box-sizing: border-box;
    background: #f8f9fa;
}

.flash-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 520px;
    box-sizing: border-box;
    padding: 15px;
    z-index: 500;
}

.message-container {
    background-color: #dddddd;
    background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23eeeeee' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.modal-backdrop {
    background-color: darken($warning, 20);
}

.bg-aside {
    background-color: $warning;
}

.bg-image {
    background: url('../images/login-background.png') no-repeat center center;
    background-size: cover;
}

#m-flash-modal-success {

    .modal-body {

        a {
            font-weight: bold;
            color: #fff;
            text-decoration: underline;
        }

    }
}